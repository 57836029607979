@import "src/styles/mixins.module";
@import "src/styles/variables.module";

.button {
  @include flex;
  @include button;
  @include transition;

  display: inline-flex;
  font-weight: 500;
  user-select: none;

  &:hover {
    text-decoration: none;
  }

  & + & {
    margin-left: 10px;
  }

  // sequence important
  &.disabled {
    opacity: 0.3;
  }

  &.loading {
    opacity: 0.5;
  }
}

@mixin hover {
  &:hover:not(.loading):not(.disabled) {
    @content;
  }
}

.middle {
  // width: 100%;
  text-align: center;
}

.button:not(.outline) {
  background-color: $blue;
  color: white;

  @include hover {
    background-color: fade-out($blue, 0.25);
  }

  &.disabled {
    background-color: $slate;
  }
}

/* outline */
.outline {
  border-width: 1px;
  border-style: solid;

  @include hover {
    opacity: 0.75;
  }

  @mixin button-outline-variant($color) {
    border-color: $color;
    color: $color;
  }

  @each $name, $color in $colors {
    &.#{$name} {
      @include button-outline-variant($color);
    }
  }

  &.secondary {
    @include button-outline-variant(fade-out(white, 0.4));
  }
}

/* block */
.block {
  width: 100%;
}

/* sizes */
@mixin button-size($font-size, $height, $padding) {
  border-radius: ($height / 2);
  font-size: $font-size;
  height: $height;
  padding: 0 $padding;
}

.xs {
  @include button-size(10px, 22px, 10px);
}

.sm {
  @include button-size(12px, 26px, 10px);
  min-width: 74px;
}

.md {
  @include button-size(14px, 36px, 20px);
  min-width: 160px;
}

.lg {
  @include button-size(16px, 50px, 30px);
  width: 100%;
}

/* swap */
.swap {
  @include button-size(16px, 50px, 30px);
  width: 60%;
  background-color: #003300;
  font-weight: 600;
  border-radius: 10px;
  text-transform: capitalize;
  max-width: 240px;
  @include mobile {
    width: 80%;
  }
}
.swap:not(.outline) {
  background-color: #FB8500;
  color: white;

  @include hover {
    background-color: #ad5d01;
  }
}

/* theme */
.submit {
  margin-top: 30px;
}

/* label */
.progress {
  margin-right: 10px;
}
