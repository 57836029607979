@import "src/styles/mixins.module";
@import "src/styles/variables.module";
@import "src/styles/reboot.module";
@import "src/styles/colors.module";
@import "src/styles/utils.module";

body {
  padding-top: 40px;
}

html,
body {
  width: 100%;
  height: 100%;
  font-size: $font-size;
  position: relative;
  font-family: $font-family-swap;
  padding-bottom: $footer-height;
  background-color: #023047 ; //GRDX, RBH

  *::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  *::-webkit-scrollbar-track {
    background-color: transparent;
  }
  *::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: lightgray;
  }
  *::-webkit-scrollbar-button {
    width: 0;
    height: 0;
  }

  @include mobile {
    padding-bottom: $footer-height-mobile;

    .desktop-only {
      display: none !important;
    }
  }

  @include desktop {
    .mobile-only {
      display: none !important;
    }
  }
}

.body {
  background-color: $realblue;
  background-repeat: no-repeat;
  background-position: 50% calc(100% + 180px);
  background-size: cover;
}

#basement {
  padding-bottom: $footer-height;

  @include mobile {
    padding-bottom: $footer-height-mobile;
  }
}

$loading_offset: 187;
$loading_duration: 1.4s;

.loading-spinner {
  animation: rotator $loading_duration linear infinite;
}

@keyframes rotator {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(270deg);
  }
}

.loading-path {
  stroke-dasharray: $loading_offset;
  stroke-dashoffset: 0;
  transform-origin: center;
  animation: dash $loading_duration ease-in-out infinite;
  stroke: #ffffff;
}

@keyframes dash {
  0% {
    stroke-dashoffset: $loading_offset;
  }
  50% {
    stroke-dashoffset: $loading_offset/4;
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: $loading_offset;
    transform: rotate(450deg);
  }
}

//*******************************************************************************************

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}
:root {
  scroll-behavior: smooth;
  font-size: 62.5%;
}
.main-container {
  width: 100%;
  padding: 5% 0;
  font-family: "Rubik", sans-serif;
  color: #fff;
  overflow-x: hidden;
}
.main-header {
  width: 100%;
  margin-bottom: 20px;
}
.main-header-navbar {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
}
.main-header-navbar__logo {
  width: 100%;
  justify-content: center;
}
.main-header-navbar__login {
  order: 1;
  width: 50%;
  max-width: 240px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
}
.main-header-navbar__login__login-btn {
  width: 35%;
  color: #fff;
  font-size: 1.3rem;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  border-right: 1px solid rgba(255, 255, 255, 0.664);
  transition: color 0.3s ease-in;
}
.main-header-navbar__login__login-btn:hover {
  color: #92b2f3;
}
.main-header-navbar__login__login-btn:active {
  color: #fbc17c;
}
.main-header-navbar__login__register-btn {
  width: 60%;
  padding: 9px 10px;
  color: #fff;
  border-radius: 25px;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  font-size: 1.3rem;
  max-width: 140px;
  transition: all 0.3s ease-in;
}
.main-header-navbar__login__register-btn:hover {
  background-color: #6491ee;
}
.main-header-navbar__login__register-btn:active {
  transform: scale(0.95);
  background-color: #0222ba;
}
.main-header-navbar__nav {
  margin-top: 15px;
  order: 2;
  width: 100%;
  list-style-type: none;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  align-items: center;
}
.main-header-navbar__nav__item {
  color: #fff;
  font-size: 1.6rem;
  font-weight: 800;
}
.main-header-navbar__nav__link {
  color: #ffffff;
  text-decoration: none;
  display: inline-block;
  transition: color 0.3s ease-in;
}
.main-header-navbar__nav__link:hover {
  color: #cc4c00;
  font-weight: 800;
}
.main-header-navbar__nav__link:active {
  color: #fbc17c;
}
.main-header-content-principal {
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.main-header-content-principal__title {
  margin-top: 10px;
  font-size: 4rem;
  color: #f0f0f0;
}
.main-header-content-principal__title span {
  color: #cc4c00;
  font-size: 4rem;
}
.main-header-content-principal__description {
  font-size: 1.6rem;
  margin: 0px;
  font-weight: 800;
  color: #f0f0f0;
  text-align: left;
  justify-content: left;
}
.main-header-content-principal__btn {
  width: 150px;
  padding: 9px 10px;
  background-color: #8e361b;
  color: #fff;
  border-radius: 25px;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  font-size: 1.4rem;
  transition: all 0.3s ease-in;
}
.main-header-content-principal__btn:hover {
  background-color: #b77943;
}
.main-header-content-principal__btn:active {
  transform: scale(0.95);
  background-color: #8e361b;
}
.main-header-content-principal__btn i {
  margin-left: 10px;
  color: #8e361b;
  background-color: #fff;
  border-radius: 50%;
  padding: 5px;
  width: 25px;
}

.main-header-content-principal__illustration {
  width: 80%;
  height: auto;
  margin-bottom: 40px;
  position: relative;
  max-width: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-header-content-principal__illustration .content {
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-header-content-principal__illustration .fire .back,
.main-header-content-principal__illustration .fire .medium,
.main-header-content-principal__illustration .fire .front {
  position: absolute;
  bottom: 0;
  width: 80%;
  padding-bottom: 100%;
  transform: rotate(45deg);
  animation: fire 2s linear infinite alternate;
}

.main-header-content-principal__illustration .fire .back {
  background: #ff6234;
  border-radius: 0 75% 75% 75%;
  animation-delay: 1s;
  left: 1%;
  width: 100%;
}

.main-header-content-principal__illustration .fire .medium {
  left: 10%;
  width: 80%;
  padding-bottom: 80%;
  background: #ff7d17;
  border-radius: 0 75% 75% 75%;
  animation-delay: 0.7s;
}

.main-header-content-principal__illustration .fire .front {
  left: 20%;
  width: 60%;
  padding-bottom: 60%;
  background: #ffb726;
  border-radius: 0 75% 75% 75%;
}

.main-header-content-principal__illustration .wood .stick {
  position: relative;
  width: 33%;
  padding-bottom: 33%;
  background: #714717;
  border-radius: 50%;
}

.main-header-content-principal__illustration .wood .stick::before {
  content: "";
  position: absolute;
  width: 80%;
  padding-bottom: 80%;
  border-radius: 50%;
  left: 10%;
  top: 10%;
  background: #975314;
}

.main-header-content-principal__illustration .wood .stick::after {
  content: "";
  position: absolute;
  width: 50%;
  padding-bottom: 50%;
  border-radius: 50%;
  left: 25%;
  top: 25%;
  background: #714717;
}

.main-header-content-principal__illustration .light {
  position: absolute;
  top: 8%;
  left: 12%;
  transform: translate(-10%, -50%);
  width: 75%;
  padding-bottom: 75%;
  border-radius: 50%;
  background: #522e2a;
  animation: light 5s linear infinite alternate;
}

.main-header-content-principal__illustration .wood {
  position: absolute;
  bottom: 0;
  left: 20%;
  width: 60%;
  display: flex;
}

.main-header-content-principal__illustration .fire {
  position: absolute;
  bottom: 4%;
  left: 20%;
  width: 60%;
  padding-bottom: 80%;
}

.main-header-content-principal__illustration .light::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 5%;
  width: 90%;
  padding-bottom: 90%;
  border-radius: 60%;
  background: #643627;
}

.main-header-content-principal__illustration .light::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 12.5%;
  width: 75%;
  padding-bottom: 75%;
  border-radius: 50%;
  background: #743d25;
}

/* Animation Keyframes */
@keyframes light {
  0% {
    opacity: 1;
    transform: translate(0px) scale(1.3);
  }
  100% {
    opacity: 0.8;
    transform: translateY(20px) scaleX(1);
  }
}

@keyframes fire {
  0% {
    opacity: 1;
    transform: scale(1) rotate(45deg);
  }
  100% {
    opacity: 0.8;
    transform: scale(0.7) rotate(45deg);
  }
}

.main-header-content-principal .discount-section {
  width: 80%;
  max-width: 230px;
  background-color: #3c308a;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  border-radius: 30px;
  padding: 5px 0px;
  animation: beat 1s 2s ease-in-out alternate 15;
}
@keyframes beat {
  from {
    transform: scale(1.05);
  }
  to {
    transform: scale(0.95);
  }
}
.main-header-content-principal .discount-section__discount {
  display: inline-block;
  padding: 5px;
  margin-left: 5px;
  border-radius: 30px;
  width: 30%;
  font-size: 1rem;
  background-color: #fff;
  color: #0d0d2b;
  font-weight: 500;
}
.main-header-content-principal .discount-section__description {
  font-size: 1rem;
  margin-right: 10px;
}
.main-header-content-container {
  width: 100%;
  height: auto;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
}
.header-container {
  width: 40%;
  margin-left: auto;
  margin-right: auto;
}
.main-content {
  width: 100%;
}
.main-content .why-us-wrapper {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
}
.main-content .why-us-wrapper .stats-section {
  width: 80%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  color: #cc4c00;
}
.main-content .why-us-wrapper .stats-section__reference {
  width: 50%;
  margin-bottom: 40px;
  text-align: center;
}
.main-content .why-us-wrapper .stats-section__reference .fas {
  color: #b77943;
  background-color: #8e361b;
  font-size: 35px;
  padding: 20px;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  text-align: center;
  line-height: 60px;
  vertical-align: middle;
}
.main-content .why-us-wrapper .stats-section__reference__title {
  font-size: 2.5rem;
  margin: 15px auto;
}
.main-content .why-us-wrapper .stats-section__reference__description {
  font-size: 1.4rem;
  color: #fff;
}
.main-content .why-us-wrapper .why-us-section {
  width: 80%;
  max-width: 400px;
  margin-bottom: 75px;
}
.main-content .why-us-wrapper .why-us-section__content {
  width: 100%;
  text-align: center;
}
.main-content .why-us-wrapper .why-us-section__content__title {
  font-size: 2.5rem;
  color: #f0f0f0;
}
.main-content .why-us-wrapper .why-us-section__content__title span {
  color: #cc4c00;
}
.main-content .why-us-wrapper .why-us-section__content__description {
  font-size: 1.4rem;
  margin: 20px auto;
  color: #fff;
  text-align: left;
}
.main-content .why-us-wrapper .why-us-section__content__btn {
  width: 150px;
  padding: 9px 10px;
  background-color: #de4721;
  color: #fff;
  border-radius: 25px;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  font-size: 1.4rem;
  margin: 20px auto 10px;
  transition: all 0.3s ease-in;
}
.main-content .why-us-wrapper .why-us-section__content__btn:hover {
  background-color: #b77943;
}
.main-content .why-us-wrapper .why-us-section__content__btn:active {
  transform: scale(0.95);
  background-color: #b77943;
}
.main-content .why-us-wrapper .why-us-section__illustration {
  width: 100%;
  animation-delay: 4s;
  animation-timing-function: ease-in-out;
  animation-duration: 2.5s;
}
.main-content .why-us-wrapper .benefits-section {
  width: 80%;
  text-align: center;
  color: #cc4c00;
}
.main-content .why-us-wrapper .benefits-section__title {
  font-size: 2.5rem;
}
.main-content .why-us-wrapper .benefits-section__title span {
  color: #ffffff;
}
.main-content .why-us-wrapper .benefits-section__description {
  font-size: 1.4rem;
  margin-top: 30px;
  color: #cc4c00;
}
.main-content .why-us-wrapper .benefits-section .card-info {
  width: 100%;
  max-width: 400px;
  background-color: #fff;
  color: #cc4c00;
  border-radius: 10px;
  padding: 25px 15px;
  transform: translateY(50%);
  text-align: left;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.5);
}
.main-content .why-us-wrapper .benefits-section .card-info__title {
  font-size: 1.4rem;
  color: #1181e8;
}
.main-content .why-us-wrapper .benefits-section .card-info__description {
  font-size: 1.3rem;
  font-weight: 700;
  margin: 15px auto;
}
.main-content .why-us-wrapper .benefits-section .card-info__description span {
  color: #cc4c00;
}
.main-content .why-us-wrapper .benefits-section .card-info__advice {
  font-size: 1rem;
  color: #979797;
}
.main-content .cryptocurrencies-section {
  width: 100%;
  padding: 60px 0 50px;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #ffffff;
}
.main-content .cryptocurrencies-section__title {
  font-size: 2.4rem;
  width: 80%;
  max-width: 700px;
  margin-bottom: 50px;
}
.main-content .cryptocurrencies-section .cryptocurrencies-info-cards {
  width: 80%;
  max-width: 400px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  align-items: center;
}
.main-content
  .cryptocurrencies-section
  .cryptocurrencies-info-cards
  .info-card {
  width: 100%;
  max-width: 290px;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  padding: 30px 0 15px;
  margin-bottom: 50px;
  border-radius: 20px;
  background-color: #0d0d2b;
  box-shadow: 0px 5px 5px rgba(139, 35, 35, 0.3),
    1px -1px 2px rgba(0, 0, 0, 0.4);
  cursor: pointer;
  transition: all 0.3s ease-in;
}
.main-content
  .cryptocurrencies-section
  .cryptocurrencies-info-cards
  .info-card:hover {
  background-color: #00006b;
  color: #fff;
  transform: scale(1.05);
}
.main-content
  .cryptocurrencies-section
  .cryptocurrencies-info-cards
  .info-card:hover
  p {
  color: #fff;
}
.main-content
  .cryptocurrencies-section
  .cryptocurrencies-info-cards
  .info-card:active {
  transform: scale(0.95);
  background-color: #4808bd;
}
.main-content
  .cryptocurrencies-section
  .cryptocurrencies-info-cards
  .info-card
  .fab {
  font-size: 80px;
}
.main-content
  .cryptocurrencies-section
  .cryptocurrencies-info-cards
  .info-card
  .fa-bitcoin {
  color: #f9aa4b;
}
.main-content
  .cryptocurrencies-section
  .cryptocurrencies-info-cards
  .info-card
  .fa-ethereum {
  color: #fff;
  padding: 10px 20px;
  font-size: 60px;
  border-radius: 50%;
  background-color: #1181e8;
}
.main-content
  .cryptocurrencies-section
  .cryptocurrencies-info-cards
  .info-card__title {
  width: 100%;
  font-size: 2rem;
  margin: 30px auto 20px;
}
.main-content
  .cryptocurrencies-section
  .cryptocurrencies-info-cards
  .info-card__title
  span {
  font-size: 1rem;
  font-weight: 400;
  color: #979797;
}
.main-content
  .cryptocurrencies-section
  .cryptocurrencies-info-cards
  .info-card__description {
  font-size: 1.4rem;
  width: 70%;
  color: #fff;
}
.main-content
  .cryptocurrencies-section
  .cryptocurrencies-info-cards
  .info-card__btn {
  width: 150px;
  padding: 9px 10px;
  background-color: #de4721;
  color: #fff;
  border-radius: 25px;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  font-size: 1.4rem;
  margin: 30px auto;
}

.main-content
  .cryptocurrencies-section
  .cryptocurrencies-info-cards
  .info-card__btn:hover {
  background-color: #0d0d2b;
}

.main-content
  .cryptocurrencies-section
  .cryptocurrencies-info-cards
  .info-card__btn
  i {
  margin-left: 10px;
  color: #3671e9;
  background-color: #fff;
  border-radius: 50%;
  padding: 5px;
  width: 25px;
}
.main-content .features-section {
  width: 100%;
  padding: 10px 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.main-content .features-section__title {
  font-size: 2.8rem;
  width: 90%;
  max-width: 600px;
  margin-bottom: 20px;
  color: #cc4c00;
}
.main-content .features-section .invest-smart-article,
.main-content .features-section .grow-profit-article,
.main-content .features-section .detailed-stats-article {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}
.main-content .features-section .invest-smart-article__content {
  width: 100%;
  margin-bottom: 50px;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
}
.main-content .features-section .invest-smart-article__content__title {
  font-size: 2rem;
}
.main-content .features-section .invest-smart-article__content__description {
  font-size: 1.4rem;
  width: 100%;
  margin: 25px auto;
}
.main-content .features-section .invest-smart-article__content__btn {
  width: 150px;
  padding: 9px 10px;
  background-color: #3671e9;
  color: #fff;
  border-radius: 25px;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  font-size: 1.4rem;
  transition: all 0.3s ease-in;
}
.main-content .features-section .invest-smart-article__content__btn:hover {
  background-color: #6491ee;
}
.main-content .features-section .invest-smart-article__content__btn:active {
  transform: scale(0.95);
  background-color: #0d3179;
}
.main-content .features-section .invest-smart-article__graphic {
  width: 100%;
}
.main-content .features-section .detailed-stats-article__content {
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
}
.main-content .features-section .detailed-stats-article__content__title {
  font-size: 2rem;
  color: #cc4c00;
}
.main-content .features-section .detailed-stats-article__content__description {
  font-size: 1.4rem;
  width: 80%;
  margin: 25px auto;
}
.main-content .features-section .detailed-stats-article__content__btn {
  width: 150px;
  padding: 9px 10px;
  background-color: #3671e9;
  color: #fff;
  border-radius: 25px;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  font-size: 1.4rem;
  transition: all 0.3s ease-in;
}
.main-content .features-section .detailed-stats-article__content__btn:hover {
  background-color: #6491ee;
}
.main-content .features-section .detailed-stats-article__content__btn:active {
  transform: scale(0.95);
  background-color: #0d3179;
}
.main-content .features-section .detailed-stats-article__graphic {
  width: 100%;
}
.main-content .features-section .grow-profit-article__content {
  width: 100%;
  margin-bottom: 50px;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
}
.main-content .features-section .grow-profit-article__content__title {
  font-size: 2rem;
}
.main-content .features-section .grow-profit-article__content__description {
  font-size: 1.4rem;
  width: 100%;
  margin: 20px;
  text-align: left;
  justify-content: left;
}
.main-content .features-section .grow-profit-article__content__btn {
  width: 150px;
  padding: 9px 10px;
  background-color: #3671e9;
  color: #fff;
  border-radius: 25px;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  font-size: 1.4rem;
  transition: all 0.3s ease-in;
}
.main-content .features-section .grow-profit-article__content__btn:hover {
  background-color: #6491ee;
}
.main-content .features-section .grow-profit-article__content__btn:active {
  transform: scale(0.95);
  background-color: #0d3179;
}
.main-content .features-section .grow-profit-article__graphic {
  width: 100%;
}

.main-content .team-section {
  width: 100%;
  padding: 0 0 50px;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #ffffff;
}
.main-content .team-section__title {
  font-size: 2.4rem;
  width: 100%;
  margin-top: 50px;
  margin-bottom: 20px;
}
.main-content .team-section .team-info-cards {
  width: 100%;

  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  align-items: center;
}
.main-content .team-section .team-info-cards .info-card {
  width: 100%;
  max-width: 290px;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  padding: 30px 0 15px;
  margin-bottom: 50px;
  border-radius: 20px;
  background-color: #0d0d2b;
  box-shadow: 0px 5px 5px rgba(139, 35, 35, 0.3),
    1px -1px 2px rgba(0, 0, 0, 0.4);
  cursor: pointer;
  transition: all 0.3s ease-in;
}

.main-content .team-section .team-info-cards .info-card .info-card__icon {
  max-width: 80%;
  max-height: auto;
}

.main-content .team-section .team-info-cards .info-card:hover {
  background-color: #00006b;
  color: #fff;
  transform: scale(1.1);
}
.main-content .team-section .team-info-cards .info-card:hover p {
  color: #fff;
}
.main-content .team-section .team-info-cards .info-card:active {
  transform: scale(0.95);
  background-color: #4808bd;
}
.main-content .team-section .team-info-cards .info-card .fab {
  font-size: 80px;
}

.main-content .team-section .team-info-cards .info-card__title {
  width: 100%;
  font-size: 2rem;
}
.main-content .team-section .team-info-cards .info-card__title span {
  font-size: 1.5rem;
  font-weight: 300;
  color: #fff;
}
.main-content .team-section .team-info-cards .info-card__description {
  font-size: 1.4rem;
  width: 70%;
  color: #fff;
  text-align: center;
}

.main-content .team-section .team-info-cards .info-card__btn:hover {
  background-color: #0d0d2b;
}

.cta-section {
  width: 80%;
  max-width: 400px;
  margin: -50px auto 75px;
  border-radius: 10px;
  padding: 25px;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
}
.cta-section__content {
  width: 100%;
  text-align: center;
  margin-bottom: 25px;
}
.cta-section__content__title {
  font-size: 2rem;
}
.cta-section__content__description {
  margin: 20px auto;
  font-size: 1.4rem;
  font-weight: 400;
}
.cta-section__subscribe {
  text-align: center;
}
.cta-section__subscribe__input {
  width: 100%;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  margin-bottom: 20px;
  padding: 10px 5px;
  transition: all 0.3s ease-in;
}
.cta-section__subscribe__input:hover {
  border-bottom: 2px solid #f9aa4b;
}
.cta-section__subscribe__input:focus {
  outline: none;
  color: #fff;
}
.cta-section__subscribe__input::placeholder {
  color: #fff;
  font-size: 1.2rem;
}
.cta-section__subscribe__btn {
  width: 150px;
  padding: 9px 10px;
  background-color: #3671e9;
  color: #fff;
  border-radius: 25px;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  font-size: 1.4rem;
  background-color: #fff;
  color: #0d0d2b;
  font-weight: 500;
  transition: all 0.3s ease-in;
}
.cta-section__subscribe__btn:hover {
  background-color: #c8c8c8;
  color: #fff;
}
.cta-section__subscribe__btn:active {
  transform: scale(0.95);
  background-color: #0d3179;
}
.footer-container {
  width: 80%;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}
.main-footer {
  width: 100%;
  background-color: #2a2e2c;
}
.main-footer__logo {
  width: 300px;
  margin-bottom: 35px;
}
.main-footer-navbar {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 50px;
  margin-top: 25px;
}
.main-footer-navbar__nav {
  width: 70%;
  list-style-type: none;
  margin-bottom: 35px;
}
.main-footer-navbar__nav__title {
  font-size: 1.5rem;
  margin-bottom: 20px;
}
.main-footer-navbar__nav__item {
  margin-bottom: 10px;
}
.main-footer-navbar__nav__link {
  text-decoration: none;
  color: #fff;
  font-size: 1.4rem;
  font-weight: 400;
  transition: color 0.3s ease-in;
}
.main-footer-navbar__nav__link:hover {
  color: #fbc17c;
}
.main-footer-navbar__nav__link:active {
  color: #fbc17c;
}
.main-footer .payment-systems {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
  margin-top: 25px;
}
.main-footer .payment-systems__title {
  font-size: 2.4rem;
  text-align: center;
  margin-bottom: 30px;
}
.main-footer .payment-systems-icons {
  width: 80%;
  max-width: 340px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
}
.main-footer .payment-systems-icons .fab {
  font-size: 50px;
  color: #fff;
  cursor: pointer;
  transition: color 0.3s ease-in;
}
.main-footer .payment-systems-icons .fab:hover {
  color: #fbc17c;
}
.main-footer .payment-systems-icons .fab:active {
  color: #fbc17c;
}
.main-footer .social-section {
  width: 30%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
  margin-top: 25px;
}
.main-footer .social-section__title {
  font-size: 2.4rem;
  text-align: center;
  margin-bottom: 30px;
  color: #de4721;
}
.main-footer .social-section-icons {
  width: 45%;
  max-width: 400px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
}

.main-footer .social-section-icons .fab:hover {
  color: #fbc17c;
}
.main-footer .social-section-icons .fab:active {
  color: #fbc17c;
}
.main-footer .copy-and-social {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}
.main-footer .copy-and-social__copy {
  text-align: center;
  width: 100%;
  font-weight: 400;
  margin-bottom: 25px;
}
.main-footer .copy-and-social .social-icons {
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  align-items: center;
}
.main-footer .copy-and-social .social-icons .fab {
  color: #eee;
  font-size: 1.6rem;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  transition: color 0.3s ease-in;
}
.main-footer .copy-and-social .social-icons .fab:hover {
  color: #92b2f3;
}
.main-footer .copy-and-social .social-icons .fab:active {
  color: #fbc17c;
}
.attribution {
  margin-top: 25px;
  width: 100%;
  background-color: #fff;
  padding: 5px;
  text-align: center;
  font-size: 1.4rem;
  color: #0d0d2b;
  font-weight: 500;
}
.attribution a {
  display: inline-block;
  margin-left: 5px;
  color: #d77907;
}
@media screen and (min-width: 399px) {
  .header-container {
    max-width: 100%;
  }

  .main-header {
    width: 100%;
    margin-right: 10px;
    margin-left: 10px;
    margin-bottom: 10px;
  }

  .main-header-navbar {
    text-align: center;
    font-size: 10px;
  }
  .main-header-navbar__nav {
    margin-top: 0;
    order: 1;
  }
  .main-header-navbar__login {
    width: 30%;
  }

  .main-header-navbar__nav__link {
    font-size: 18px;
  }

  .main-header-content-principal {
    width: 80%;
    justify-content: flex-start;
    text-align: left;
  }

  .main-header-content-principal__title {
    margin-top: 10px;
  }

  .main-content .why-us-wrapper .stats-section {
    margin-top: 20px;
    width: 90%;
    flex-flow: row nowrap;
  }
  .main-content .why-us-wrapper .stats-section__reference__title {
    height: 75px;
  }
  .main-content .why-us-wrapper .stats-section__reference {
    width: 33%;
    margin-bottom: 0;
    height: 260px;
  }

  .main-content .why-us-wrapper .why-us-section {
    margin-top: 20px;
    width: 90%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
  }
  .main-content .why-us-wrapper .why-us-section__content {
    width: 45%;
    order: 1;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center;
    text-align: left;
    margin: 25px 0;
  }
  .main-content .why-us-wrapper .why-us-section__illustration {
    width: 45%;
  }
  .main-content .why-us-wrapper .benefits-section {
    max-width: 80%;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
  }
  .main-content .why-us-wrapper .benefits-section__title {
    width: 90%;
  }
  .main-content .why-us-wrapper .benefits-section__description {
    width: 70%;
  }
  .main-content .why-us-wrapper .benefits-section .card-info {
    max-width: 50%;
  }
  .main-content .why-us-wrapper .cryptocurrencies-section__title {
    width: 100%;
  }
  .main-content .cryptocurrencies-section .cryptocurrencies-info-cards {
    width: 95%;
    max-width: 1050px;
  }

  .main-content .features-section {
    width: 100%;
  }

  .main-content .features-section__title {
    font-size: 4rem;
    max-width: 580px;
  }
  .main-content .features-section .invest-smart-article__content {
    width: 100%;
    margin: 40px 0;
    justify-content: flex-start;
    text-align: left;
  }
  .main-content .features-section .invest-smart-article__content__title {
    font-size: 2.6rem;
  }
  .main-content .features-section .invest-smart-article__content__description {
    margin: 25px 0;
    width: 100%;
  }
  .main-content .features-section .invest-smart-article__graphic {
    width: 50%;
  }
  .main-content .features-section .detailed-stats-article__content {
    width: 80%;
    margin: 20px 0;
    justify-content: flex-start;
    text-align: left;
  }
  .main-content .features-section .detailed-stats-article__content__title {
    font-size: 2.6rem;
  }
  .main-content
    .features-section
    .detailed-stats-article__content__description {
    margin: 25px 0;
  }
  .main-content .features-section .detailed-stats-article__graphic {
    width: 55%;
    order: -1;
  }
  .main-content .features-section .grow-profit-article {
    justify-content: space-between;
  }
  .main-content .features-section .grow-profit-article__content {
    width: 45%;
    margin: 40px 0;
    justify-content: flex-start;
    text-align: left;
  }
  .main-content .features-section .grow-profit-article__content__title {
    font-size: 2.6rem;
  }
  .main-content .features-section .grow-profit-article__content__description {
    margin: 0;
    padding: 30px;
  }
  .main-content .features-section .grow-profit-article__graphic {
    width: 55%;
  }
  .cta-section {
    max-width: 750px;
    justify-content: space-between;
  }
  .cta-section__content {
    width: 50%;
    margin: 0;
    text-align: left;
  }
  .cta-section__subscribe {
    width: 45%;
    margin: 0;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
  }
  .footer-container {
    width: 90%;
    max-width: 00px;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: flex-start;
  }
  .main-footer-navbar {
    width: 40%;
    align-items: flex-start;
  }
  .main-footer-navbar__nav {
    width: 50%;
  }
  .main-footer .payment-systems {
    width: 35%;
  }
  .main-footer .payment-systems-icons {
    width: 100%;
  }
  .main-footer .copy-and-social {
    justify-content: space-between;
  }
  .main-footer .copy-and-social__copy {
    width: 200px;
    margin-bottom: 0;
  }
  .main-footer .copy-and-social .social-icons {
    max-width: 200px;
    justify-content: space-between;
  }
}

@media screen and (min-width: 681px) {
  .main-container {
    padding: 50px 0;
  }
  .header-container {
    max-width: 1200px;
  }
  .main-header-navbar__login__register-btn {
    padding: 15px 10px;
  }
  .main-header-content-principal__title {
    font-size: 4rem;
    line-height: 50px;
  }
  .main-header-content-principal__description {
    font-size: 1.8rem;
    line-height: 30px;
  }
  .main-header-content-principal__btn {
    padding: 15px;
  }
  .main-header-content-principal__illustration {
    margin-top: 10px;
    width: 50%;
  }
  .main-content .why-us-wrapper {
    flex-flow: column nowrap;
  }
  .main-content .why-us-wrapper .stats-section {
    max-width: 1100px;
  }
  .main-content .why-us-wrapper .why-us-section {
    width: 80%;
    max-width: 1200px;
  }
  .main-content .why-us-wrapper .why-us-section__content {
    width: 40%;
  }
  .main-content .why-us-wrapper .why-us-section__content__title {
    font-size: 3.3rem;
  }
  .main-content .why-us-wrapper .why-us-section__content__description {
    font-size: 1.8rem;
    line-height: 25px;
  }
  .main-content .why-us-wrapper .why-us-section__content__btn {
    margin: 20px 0;
    padding: 15px 10px;
  }
  .main-content .why-us-wrapper .why-us-section__illustration {
    width: 40%;
  }
  .main-content .why-us-wrapper .benefits-section {
    flex-flow: column nowrap;
  }
  .main-content .why-us-wrapper .benefits-section__title {
    font-size: 3rem;
  }
  .main-content .why-us-wrapper .benefits-section__description {
    font-size: 1.7rem;
  }
  .main-content .why-us-wrapper .benefits-section .card-info {
    max-width: 650px;
    padding: 35px;
  }
  .main-content .why-us-wrapper .benefits-section .card-info__title {
    font-size: 1.8rem;
  }
  .main-content .why-us-wrapper .benefits-section .card-info__description {
    font-size: 2.3rem;
  }
  .main-content .why-us-wrapper .benefits-section .card-info__advice {
    font-size: 1.5rem;
  }
  .main-content .cryptocurrencies-section {
    flex-flow: column nowrap;
  }
  .main-content .cryptocurrencies-section__title {
    font-size: 3rem;
    margin: 50px auto;
  }
  .main-content .cryptocurrencies-section .cryptocurrencies-info-cards {
    max-width: 1150px;
  }
  .main-content .features-section {
    flex-flow: column nowrap;
  }
  .main-content .features-section__title {
    font-size: 3.5rem;
    max-width: 700px;
  }
  .main-content .features-section .invest-smart-article,
  .main-content .features-section .detailed-stats-article,
  .main-content .features-section .grow-profit-article {
    max-width: 1100px;
    justify-content: space-evenly;
    margin-bottom: 20px;
  }
  .main-content .features-section .invest-smart-article__content,
  .main-content .features-section .detailed-stats-article__content,
  .main-content .features-section .grow-profit-article__content {
    width: 50%;
    margin: 0;
  }
  .main-content .features-section .invest-smart-article__content__title,
  .main-content .features-section .detailed-stats-article__content__title,
  .main-content .features-section .grow-profit-article__content__title {
    font-size: 3rem;
  }
  .main-content .features-section .invest-smart-article__content__description,
  .main-content .features-section .detailed-stats-article__content__description,
  .main-content .features-section .grow-profit-article__content__description {
    font-size: 1.6rem;
    color: #fff;
    padding: 30px;
  }
  .main-content .features-section .invest-smart-article__content__btn,
  .main-content .features-section .detailed-stats-article__content__btn,
  .main-content .features-section .grow-profit-article__content__btn {
    padding: 15px 10px;
  }
  .cta-section {
    max-width: 850px;
  }
  .cta-section__content {
    width: 30%;
  }
  .cta-section__subscribe {
    justify-content: space-evenly;
    width: 60%;
  }
  .cta-section__subscribe__input {
    width: 55%;
  }
  .cta-section__subscribe__btn {
    padding: 15px 10px;
  }
  .footer-container {
    max-width: 1200px;
  }
  .main-footer-navbar {
    max-width: 300px;
  }
  .main-footer .payment-systems {
    max-width: 300px;
  }
  .main-footer .payment-systems-icons {
    width: 80%;
  }

  .svg-container {
    position: relative;
    width: 140mm;
    height: 140mm;
  }

  .svg-container svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .svg-container svg.rotating-svg {
    animation: rotate 5s linear infinite;
    transform-origin: center;
    transform-box: fill-box;
    transform: translate(-50%, -50%);
  }

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(-360deg);
    }
  }






} /*# sourceMappingURL=main.css.map */
